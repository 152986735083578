import { render, staticRenderFns } from "./NewProductStepOne.vue?vue&type=template&id=bdfe09a6&scoped=true&"
import script from "./NewProductStepOne.vue?vue&type=script&lang=ts&"
export * from "./NewProductStepOne.vue?vue&type=script&lang=ts&"
import style0 from "./NewProductStepOne.vue?vue&type=style&index=0&id=bdfe09a6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bdfe09a6",
  null
  
)

export default component.exports