




























import Vue from 'vue'
import Component from 'vue-class-component'
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import SygniLoader from '@/components/layout/SygniLoader.vue';
import {AddProductSelects} from "@/modules/shared/dictionary-store/types";
import {emptyLoanProduct, emptyBillProduct, emptyJointStockSharesProduct, emptyLlcProduct, NewProduct, ProductTypes} from "@/modules/genprox/modules/fund/modules/capital-rise/store/types";
import {required} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";
import { SelectOption } from '@/store/types';
import _ from 'lodash';

Component.registerHooks(['validations'])
@Component({
  components: { SygniRoundedButton, SygniRectButton, SygniSelect, SygniContainerTitle, SygniLoader },
  computed: {
    ...mapGetters('dictionary', {
      productSelects: 'addProductsSelects'
    })
  }
})
export default class AddNewProductStepOne extends Vue {
  productSelects!: AddProductSelects;
  investorOptions: SelectOption[] = []
  selectedInvestor: string = null
  isLoading: boolean = false
  investorChosen: boolean = false
  reRender: number = 0

  product: NewProduct = {} as NewProduct;

  get investorId() {
    return this.$route.params?.investorId || null
  }

  get annexingProducts() {
    return this.$store.getters['investors/annexingProducts'];
  }

  get isSubscription() {
    return !!(this.product?.type === 'JOINT_STOCK_SHARES' || this.product?.type === 'LLC_SHARES')
  }

  async created() {
    this.isLoading = true

    try {
      const investors = await this.$store.dispatch('investors/getAllInvestors')
      this.investorOptions = investors.map((el: any) => {
        return { value: el.id, label: el.name }
      })
    } catch (e) {
      e;
    }

    this.isLoading = false
  }

  async beforeMount() {
    if (this.annexingProducts?.length === 1) {
      const response = await this.$store.dispatch('investors/getProduct', { type: 'for-legal-entity', id: this.annexingProducts[0]?.id });
      const productData: any = response.data;
      productData.autogeneratedCode = true;
      productData.code = null;
      productData.name = null;
      this.$store.commit('investors/setNewProduct', productData)
    } else {
      if (this.$route?.params?.productType === 'JOINT_STOCK_SHARES') {
        this.$store.commit('investors/setNewProduct', _.cloneDeep(emptyJointStockSharesProduct));
      } else if (this.$route?.params?.productType === 'LLC_SHARES') {
        this.$store.commit('investors/setNewProduct', _.cloneDeep(emptyLlcProduct));
      } else {
        this.$store.commit('investors/setNewProduct', _.cloneDeep(emptyLoanProduct));
      }
    }

    if (this.$route.params?.investorId) {
      if (this.$route.params?.isLegalEntity === '1') {
        this.$store.commit('investors/setNewProductLe', this.$route.params?.investorId);
      } else {
        this.$store.commit('investors/setNewProductInvestor', this.$route.params?.investorId);
      }

      this.investorChosen = true
    }

    if (this.$route.params?.subscriptionId) {
      if (this.$route.params?.productType === 'JOINT_STOCK_SHARES') {
        this.$store.commit('investors/setNewProduct', _.cloneDeep(emptyJointStockSharesProduct))
      } else if (this.$route.params?.productType === 'LLC_SHARES') {
        this.$store.commit('investors/setNewProduct', _.cloneDeep(emptyLlcProduct))
      }
      this.$store.commit('investors/setNewProductSubscriptionId', this.$route.params?.subscriptionId)
    }

    this.product = this.$store.getters['investors/newProduct'];

    if ((this.product?.type === 'JOINT_STOCK_SHARES' || this.product?.type === 'LLC_SHARES') && (this.$route.params?.investorId)) {
      this.goNext()
    }
  }

  handleAddOption() {
    const subscriptionString = this.product?.subscriptionId ? `&subscriptionId=${this.product?.subscriptionId}` : ''
    this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/capital-rise/new-investor/form/step-one?type=subscription_product${subscriptionString}&productType=${this.product?.type}` })
  }

  setProductType(value: string) {
    if(!this.annexingProducts?.length) {
      if(value === ProductTypes.billAgreement) {
        this.$store.commit('investors/setNewProduct', _.cloneDeep(emptyBillProduct));
        this.product = this.$store.getters['investors/newProduct'];
      } else {
        this.$store.commit('investors/setNewProduct', _.cloneDeep(emptyLoanProduct));
        this.product = this.$store.getters['investors/newProduct'];
      }
    }
  }

  goNext() {
    this.$v.$touch();
    if(!this.$v.$error) {
      if (this.$route.params?.subscriptionId) {
        if (this.$route.params?.isLegalEntity === '1') {
          this.$store.commit('investors/setNewProductLe', this.$route.params?.investorId);
        } else {
          this.$store.commit('investors/setNewProductInvestor', this.$route.params?.investorId || this.selectedInvestor);
        }
        this.$router.push('step-two')
        return
      }

      if (this.investorChosen) {
        this.$router.push('step-two');
        return
      }

      if (!this.$route.params?.investorId || !this.investorChosen) {
        this.$store.commit('investors/setNewProductInvestor', this.selectedInvestor);
        this.investorChosen = true
        this.reRender++
      }

    }
  }

  goBack(){
    this.$router.back();
  }

  get validationObject() {
    if (this.$route.params?.investorId) {
      return {
        product: {
          type: { required }
        }
      }
    }

    return {
      selectedInvestor: { required }
    }
  }

  validations () {
    return this.validationObject
  }
}
